import React, { useContext } from "react"
import { ThemeContext } from "src/providers/ThemeProvider"
import { Container } from "src/components/common"
import { Wrapper, ContentWrapper, Head } from "./styles"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ToggleLanguage from 'src/components/theme/Header/ToggleLanguage';


export const Content = ({ content }) => {
  const { theme } = useContext(ThemeContext)
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      logowhite: file(relativePath: { eq: "logo-medienkram-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      logoblack: file(relativePath: { eq: "logo-medienkram-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <Wrapper id="contact" theme={theme}>
      <Head theme={theme}>
        <a href="/" title="Medienkram" aria-current="page">
          <Img
            fixed={
              theme === "dark"
                ? data.logowhite.childImageSharp.fixed
                : data.logoblack.childImageSharp.fixed
            }
          />
        </a>
        <ToggleLanguage />
      </Head>
      <ContentWrapper as={Container}>
        <h2 dangerouslySetInnerHTML={{ __html: t(content + ":title") }} />
        <div dangerouslySetInnerHTML={{ __html: t(content + ":text") }} />
      </ContentWrapper>
    </Wrapper>
  )
}
