import { Link, useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { List,ListItem } from "./styles"
const ToggleLanguage = () => {
  const { language, languages, originalPath } = useI18next()

  return (
    <List className="languages">
      {languages.map(lng => (
        <ListItem key={lng} active={language === lng ? true : false}>
          <Link
            to={originalPath}
            language={lng}
            
          >
            {lng}
          </Link>
        </ListItem>
      ))}
    </List>
  )
}

export default ToggleLanguage
