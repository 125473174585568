import styled from "styled-components"

export const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 15px 0 0 0;
  @media (min-width: 960px) {
    margin: 0 0 0 15px;
  }
`

export const ListItem = styled.li`
  text-transform: uppercase;
  margin-left: 10px;
  margin-bottom: 0;
  &:first-child {
    margin-left: 0;
  }
  ${({ active }) =>
    active &&
    `
    // border-bottom: 1px solid black;
    font-family: "raleway-bold"
  `}
`
