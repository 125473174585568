import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"

import { ThemeContext } from 'src/providers/ThemeProvider';
import { Container } from 'src/components/common';
import NavbarLinks from '../NavbarLinks';
import { Wrapper, Brand } from './styles';

const Navbar = () => {
  const { theme } = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query {
      logowhite: file(relativePath: { eq: "logo-medienkram-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          },
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
         
        }
      },
      logoblack: file(relativePath: { eq: "logo-medienkram-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          },
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
         
        }
      }
    }
  `)

  return (
    <Wrapper as={Container}>

      <Brand aria-current="page" title="Medienkram" href="/" >
        <Img fixed={(theme === 'dark' ? data.logowhite.childImageSharp.fixed : data.logoblack.childImageSharp.fixed)} /> 
      </Brand>
      <NavbarLinks desktop />
   
    </Wrapper>
  );
};

export default Navbar;
