import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${({ theme }) =>
    theme === "light" ? "white" : "var(--blue-dark)"};
`
export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  a {
    color: ${({ theme }) =>
      theme === "light" ? "hsla(0, 0%, 0%, 0.8)" : "#fff"};
  }
`

export const ContentWrapper = styled.div`
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 992px) {
    padding: 4rem 2rem;
  }
  strong {
    font-family: "raleway-bold";
    margin-bottom: 10px;
    display: inline-block;
  }
`
