import React, { useContext } from "react"
import { Container } from "src/components/common"
import { Wrapper, Flex, Details, Logo, Social, Legend } from "./styles"
import Img from "gatsby-image"
import { ThemeContext } from "src/providers/ThemeProvider"

import { useStaticQuery, graphql } from "gatsby"
import linkedin from "assets/icons/linkedin-rounded.svg"
import xing from "assets/icons/xing-rounded.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"

// import social from './social.json';

export const Footer = () => {
  const { theme } = useContext(ThemeContext)
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      logowhite: file(relativePath: { eq: "logo-medienkram-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      logoblack: file(relativePath: { eq: "logo-medienkram-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Flex as={Container}>
        <Details>
          <Logo>
            <a href="/" title="Medienkram"  aria-current="page">
            <Img
              fixed={
                theme === "dark"
                  ? data.logowhite.childImageSharp.fixed
                  : data.logoblack.childImageSharp.fixed
              }
            />
            </a>
          </Logo>
          <Social>
            <a
              href="https://www.linkedin.com/in/stefanhelker"
              target="_blank"
              rel="noopener noreferrer"
              title="LinkedIn"
            >
              <img src={linkedin} alt="LinkedIn Logo" />
            </a>
            <a
              href="https://www.xing.com/profile/Stefan_Helker"
              target="_blank"
              rel="noopener noreferrer"
              title="Xing"
            >
              <img src={xing} alt="Xing Logo" />
            </a>
          </Social>
        </Details>
        <Legend theme={theme}>
          <a href="/privacy" title={t("privacy")}>{t("privacy")}</a> |{" "}
          <a href="/legal" title={t("legal")}>{t("legal")}</a> | {new Date().getFullYear()}
        </Legend>
      </Flex>
    </Wrapper>
  )
}
