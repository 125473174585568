import React, { useState } from 'react';
import { ItemWrapper, MoreLink, ItemWrapperInner } from './styles';
import { useTranslation } from "gatsby-plugin-react-i18next"

export const ReadMore  = ({ align, children }) => {
  // const { theme } = useContext(ThemeContext);
  const [show, toggle] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <MoreLink align={align} onClick={() => toggle(!show)} data-sal="fade" data-sal-duration="500" data-sal-delay="800">{show ? t("readLess"):t("readMore")}</MoreLink>
      <ItemWrapper show={show}>
        <ItemWrapperInner>
        {children}
        </ItemWrapperInner>
      </ItemWrapper>
    </>
  );
};
