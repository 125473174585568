import styled from "styled-components"

export const MoreLink = styled.div`
  transition: border 350ms ease 0s, opacity 0.5s ease 0.8s !important;

  cursor: pointer;
  font-family: "raleway-bold";
  font-size: 0.85rem;
  margin: 30px 0 0 0;
  border: 2px solid transparent;
  display: inline-block;

  @media (min-width: 576px) {
    margin: 0;
  }
  ${({ align }) =>
    align === "right" &&
    `
    @media (min-width: 576px) {
      margin-left: 45%;
    }
  `}
  &:hover {
    
    border-bottom-color: var(--dark-1);
  }
`
export const ItemWrapperInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 100%;
    padding: 0;
    margin-top: 30px;
    @media (min-width: 576px) {
      padding: 0 10px;
      width: calc(50% - 20px);
    }
    @media (min-width: 992px) {
      width: calc(50% - 60px);
      padding: 0 30px;
    }
    h5 {
      font-family: "raleway-bold";
      font-size: 1.2rem;
      margin: 0.5rem 0 0.5rem 0;
    }
    p {
      font-family: "raleway-regular";
      font-size: 0.85rem;
      line-height: 1.5rem;
      margin: 0;
    }
  }
`

export const ItemWrapper = styled.div`
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  max-height: 0;

  ${({ show }) =>
    show &&
    `
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  `}
`
