import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Wrapper, BackgroundShape } from "./styles"

export const ReferenceImage = ({ filename, alt, color, shape }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { relativeDirectory: { eq: "references" } }) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })
      if (!image) {
        return null
      }

      return (
        <Wrapper> 
          <Img alt={alt} fixed={image.node.childImageSharp.fixed} />
          <BackgroundShape background={color || false} shape={shape || false}  />
        </Wrapper>
      )
    }}
  />
)
