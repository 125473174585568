import styled from "styled-components"

export const Wrapper = styled.div`
  a {
    text-decoration: none;
    color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};

    transition: border 350ms ease 0s;
    border: 1px solid transparent;
    display: inline-block;
		@media (min-width: 961px) {
			&:hover {
				border-bottom-color: ${({ theme }) =>
					theme === "light" ? "var(--dark-1)" : "var(--blue-light)"};
			}
		}
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem 2rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1.25rem;
					font-size: 1.25rem; 
					font-weight: 600;

					&:last-child {
							margin-bottom: unset;
					}
					
			}
	`}
`
