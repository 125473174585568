module.exports = {
  defaultTitle: 'medienkram by Stefan Helker',
  logo: 'LOGO.jpg',
  author: 'Stefan Helker',
  url: 'https://medienkram.de',
  legalName: 'Stefan Helker',
  defaultDescription: '',
  socialLinks: {
    
  },
  googleAnalyticsID: '',
  themeColor: '#0e3b6b',
  backgroundColor: '#0e3b6b',
  social: {
   
  },
  address: {
    city: 'Köln',
    region: 'NRW',
    country: 'Germany',
    zipCode: '50931',
  },
  contact: {
    email: 'anfrage@medienkram.de',
    phone: '+4915678567932',
  },
  foundingDate: '2018',
};
