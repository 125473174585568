import React, {useContext} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'src/providers/ThemeProvider';
import ToggleTheme from 'src/components/theme/Header/ToggleTheme';
import ToggleLanguage from 'src/components/theme/Header/ToggleLanguage';
import { Wrapper } from './styles';
import { useTranslation } from "gatsby-plugin-react-i18next"

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation()
  return (
    <Wrapper desktop={desktop} theme={theme}>
      <AnchorLink href="#focus">{t("focus")}</AnchorLink>
      <AnchorLink href="#references">{t("references")}</AnchorLink>
      <AnchorLink href="#contact">{t("contact")}</AnchorLink>
      <ToggleTheme />
      <ToggleLanguage />
    </Wrapper>
  )

};

export default NavbarLinks;
