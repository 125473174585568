import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;



  img {
    max-width: 400px;

    z-index: 10;
    @media (max-width: 576px) {
      object-fit: contain !important;
    }
  }
  
  > div {
    > img {
      display: none;
    }
  }
`
export const BackgroundShape = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  &:after {
    display: block;
    content: "";
    background: #f6f7f9;
    width: 350px;
    height: 350px;
    border-radius: 40% 60% 35% 65% / 61% 73% 27% 39%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    


    max-width: 100%;
    max-height: 100%;

    @media (min-width: 576px) {
      max-width: 300px;
      max-height: 300px;
    }

    ${({ background }) =>
      background &&
      `
        background: ${background};
      `}
    ${({ shape }) =>
      shape &&
      ` 
      border-radius: ${shape};
      `}
    ${({ width }) =>
      width &&
      `
        width: ${width * 0.5};
        @media (min-width: 992px) {
          width: ${width};
        }
      `}
    ${({ height }) =>
      height &&
      `
      height: ${height * 0.5};
      @media (min-width: 992px) {
        height: ${height};
      }
      `}
    
      @media (min-width: 992px) {
        max-width: none;
        max-height: none;
    }
  }
`

export const ItemWrapper = styled.div``
