import styled from "styled-components"

export const RoundedIcon = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #f9d3ad;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  ${({ background }) =>
    background &&
    `
		background-color: ${background};
  `}
  ${({ small }) =>
    small &&
    `
		width: 30px;
    height: 30px;
  `}
  > img {
    width: 30px;
    height: auto;
    margin: 0;
    ${({ small }) =>
    small &&
    `
		width: 14px;
    height: 14px;
  `}
  }
`
