// import footerIllustration from 'assets/illustrations/footer.svg';
import styled from "styled-components"

// background-image: url(${footerIllustration});
export const Wrapper = styled.div`
  padding: 3rem 0 2rem 0;

  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @media (min-width: 992px) {
    padding: 3rem 0 2rem;
  }
`

export const Flex = styled.div`
  width: 100%;
  // display: flex;
  // align-items: flex-end;
  // justify-content: space-between;
  // @media (min-width: 576px) {
  //   flex-direction: column;
  //   text-align: center;
  //   align-items: center;
  // }
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  a {
    margin: 0 0.5rem;
    img {
      margin: 0;
    }
    &:first-child,
    &:last-child {
      margin: 0;
    }

    
  }
`

export const Details = styled.div`
  width: 100%;

  h2,
  a,
  span {
    color: #212121;
  }

  @media (min-width: 576px) {
    display: flex;
    justify-content: space-between;
  }
`

export const Logo = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 20px;
  text-align: center;
  @media (min-width: 576px) {
    text-align: left;
    margin-bottom: 0;
  }
  @media (min-width: 992px) {
    width: auto;
  }
`

export const Social = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    margin: 0 10px;
    width: 40px;
    height: auto;
  }
`

export const Legend = styled.div`
  text-align: center;

  h2,
  a,
  span {
    color: #212121;
    color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
  }

  a {
    transition: border 350ms ease 0s;
    border: 1px solid transparent;
    display: inline-block;
    &:hover {
      border-bottom-color: ${({ theme }) =>
        theme === "light" ? "var(--dark-1)" : "var(--blue-light)"};
    }
  }
`
