import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
   
  @media (min-width: 576px) {

  }
`

export const Brand = styled.a`
  display: block;
  color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};

  img {
    padding-right: 20px;
    object-fit:contain !important;
    @media (min-width: 576px) {
      padding-right: 0;
      object-fit:cover !important;
    }
  }
`
